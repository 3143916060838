import React from 'react';

const CheckboxCheckIcon = () => (
  <svg width="10" height="7" viewBox="0 0 10 7">
    <g fill="none" fillRule="evenodd" strokeLinecap="round">
      <g stroke="#FFF" strokeWidth="2" className="stroke-g">
        <path d="M0 3L3 0 10 7" transform="matrix(1 0 0 -1 0 7)" />
      </g>
    </g>
  </svg>
);

export default CheckboxCheckIcon;