import React from 'react';

const CloseIcon = () => (
  <svg width="48px" height="48px" viewBox="0 0 48 48" version="1.1">
    <title>Button - Close - Normal</title>
    <g id="Button---Close---Normal" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <g stroke="#FFFFFF" className="stroke-g" strokeWidth="3">
        <line x1="31" y1="31" x2="18" y2="18" id="Line_18"></line>
        <line x1="18" y1="31" x2="31" y2="18" id="Line_19"></line>
      </g>
    </g>
  </svg>
);

export default CloseIcon;
