import React from 'react';

const ChevronDownIcon = () => (
  <svg width="10px" height="5px" viewBox="0 0 10 5" version="1.1">
    <title>chevron - down - normal</title>
    <g id="chevron---down---normal" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <g id="arrow" stroke="#2e3d1a" className="stroke-g">
        <polyline id="Path-3-Copy-4" transform="translate(5.000000, 2.500000) rotate(-180.000000) translate(-5.000000, -2.500000) " points="-4.26186864e-13 5 5 0 10 5"></polyline>
      </g>
    </g>
  </svg>
);

export default ChevronDownIcon;
