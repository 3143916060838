import React from 'react';

const EmailIcon = () => (
  <svg width="41" height="28" viewBox="0 0 41 28">
    <defs>
      <path id="xgvaj64rta" d="M0 0L41 0 41 28 0 28z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g transform="translate(-663.000000, -792.000000) translate(643.000000, 766.000000) translate(20.000000, 26.000000)">
            <mask id="04ux4r6xpb" fill="#fff" className="fill-g">
              <use xlinkHref="#xgvaj64rta" />
            </mask>
            <path fill="#FFF" d="M37.802 25.453H4.488c-.708 0-1.925-1.204-1.925-1.907V5.31L19.665 18.9c.243.206.54.31.837.31.297 0 .599-.104.841-.31L38.441 5.31v18.236c0 .703.07 1.907-.639 1.907zm.02-22.906l-17.32 13.707L3.256 2.601c.396-.074.87-.054 1.232-.054h33.334zm3.133.3c.025-.143-.03-.26-.059-.403C40.649.855 39.644 0 37.802 0H4.488C2.9 0 1.191.6.42 1.78c-.034.024-.074.02-.104.049-.277.315-.316.703-.237 1.077-.01.103-.08.172-.08.275v20.365c0 2.109 2.367 4.454 4.49 4.454h33.314C39.926 28 41 25.655 41 23.546V3.18c0-.128-.035-.216-.045-.334z" mask="url(#04ux4r6xpb)" className="fill-g" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default EmailIcon;
