import React from 'react';

const ChevronRightIcon = () => (
  <svg width="12px" height="24px" viewBox="0 0 12 24" version="1.1">
    <title>chevron-right-normal</title>
    <g id="chevron-right-normal" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <polyline id="chevron-right" className="stroke-g" stroke="#426252" strokeWidth="2" points="0 24 12 12 0 0"></polyline>
    </g>
  </svg>
);

export default ChevronRightIcon;
