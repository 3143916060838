import React from 'react';

export const CreatePDFIcon = ({ color = '#E58061' }) => (
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M6.10003 7.39636C5.89912 7.62212 5.57458 7.73499 5.14185 7.73499H4.74003V8.82613H4.09094V4.91309H5.1264C5.57458 4.91309 5.89912 5.04477 6.10003 5.27053C6.30094 5.49628 6.40912 5.85372 6.40912 6.34285C6.40912 6.81317 6.3164 7.17061 6.10003 7.39636ZM5.11094 5.66559H4.74003V6.98248H5.17276C5.38912 6.98248 5.54367 6.92605 5.62094 6.81317C5.69822 6.70029 5.74458 6.53098 5.74458 6.30523C5.74458 6.07947 5.69822 5.91016 5.59003 5.8161C5.48185 5.72203 5.32731 5.66559 5.11094 5.66559Z" fill={color} />
    <path fillRule="evenodd" clipRule="evenodd" d="M11.0455 6.85016C11.0455 7.47077 10.9017 7.94094 10.5783 8.29826C10.2548 8.65558 9.7696 8.84365 9.12267 8.82484H7.95459V4.91309H9.15861C9.7696 4.91309 10.2368 5.08234 10.5603 5.42086C10.8838 5.75938 11.0455 6.24835 11.0455 6.85016ZM9.21252 8.07258C9.55396 8.07258 9.82351 7.97855 10.0032 7.77168C10.1829 7.56481 10.2907 7.2639 10.3087 6.88777C10.3087 6.51164 10.2009 6.21074 10.0212 6.00386C9.82351 5.79699 9.53599 5.68415 9.15861 5.68415H8.72732V8.07258H9.21252Z" fill={color} />
    <path d="M14.9091 5.68441V4.91309H12.5909V8.82613H13.3399V7.28349H14.82V6.53098H13.3399V5.68441H14.9091Z" fill={color} />
    <path d="M16.4904 1H2.46311C1.65027 1 1 1.53261 1 2.19837V16.2364C1 16.9022 1.65027 17.4348 2.46311 17.4348H5.69126L5.25 17.0734L4.80874 16.712H2.48634C2.1612 16.712 1.90574 16.5027 1.90574 16.2364V2.19837C1.90574 1.93207 2.1612 1.72283 2.48634 1.72283H16.5369C16.862 1.72283 17.1175 1.93207 17.1175 2.19837V16.2364C17.1175 16.5027 16.862 16.712 16.5369 16.712H14.5396L14.0984 17.0734L13.6571 17.4348H16.5369C17.3497 17.4348 18 16.9022 18 16.2364V2.19837C17.9768 1.53261 17.3033 1 16.4904 1Z" fill={color} />
    <path d="M13.3636 14.6858L10.8444 14.666V10.3914H7.38288V14.6066L4.86365 14.5671L9.17134 19.0001L13.3636 14.6858Z" fill={color} />
  </svg>
);
