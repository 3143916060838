import React from 'react';

const ChevronLeftIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="24" viewBox="0 0 12 24">
    <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" opacity=".5">
      <g className="stroke-g" stroke="#5B7862" strokeWidth="2">
        <g>
          <g>
            <path d="M0 24L12 12 0 0" transform="translate(-20 -962) translate(20 908) translate(0 54) matrix(-1 0 0 1 12 0)" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ChevronLeftIcon;